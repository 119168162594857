import React,{useState} from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
const url = require('url');
const chrono = require('chrono-node');


const LANES = {
    passenger: "passenger_vehicle_lanes",
    commercial: "commercial_vehicle_lanes"
}

const LANE_TYPES = {
    standard: "standard_lanes",
    fast: "FAST_lanes",
    nexus: "NEXUS_SENTRI_lanes",
    ready: "ready_lanes"
}

const LANE_TYPES_HISTORICAL = {
    standard: "standard",
    fast: "fast",
    ready: "ready",
    nexus: "fast",
}

const getHour = (updateTime) => {
    if(!updateTime) {
        return null
    }
    let date = chrono.parseDate(updateTime)
    if(!date) {
        return null
    }
    return date.getHours()
}

const getAverageForHour = (lane, laneType, hour, port) => {
    let times = []
    
    try {
        if (lane == "passenger") {
            times = port.data[0].private_time_slots.private_slot
        }
        if (lane == "commercial") {
            times = port.data[0].commercial_time_slots.commercial_slot
        }
    
        for (let time of times) {
            if (time.time == hour) {
                return time[`${LANE_TYPES_HISTORICAL[laneType]}_lane_average_wait`]
            }
        }
    
    } catch(e) {

    }


    return null
}

const getClassForDelay = (delay) => {

    delay = parseInt(delay)

    switch(true) {
        case delay < 30: 
            return "text-success"
        case delay >= 30 && delay < 45:
            return "text-warning"
        case delay > 45:
            return "text-danger"
        default:
            return ''
    }

    return null
}

const Lane = ({ title, lane, laneType, port }) => {

    let data = port.portByPortNumber.data[LANES[lane]][LANE_TYPES[laneType]]
    let hour = getHour(data.update_time)
    let avg = getAverageForHour(lane, laneType, hour, port)

    if(data.lanes_open == 0) {
        return null
    }

    return (
        <div className={'p-1 m-1 ' + getClassForDelay(data.delay_minutes)}>
            <strong>{title} <small className='text-muted'>{data.lanes_open} lanes open</small></strong>
            {/*<div>{data.update_time} hour={hour} avg={avg}</div>*/}

            {data.operational_status == 'no delay' ? 
                <div>
                    No delay
                </div>
            : 
                <div>
                    {data.delay_minutes} minute {' '}
                    {data.operational_status}
                </div>            
            }
            
        </div>
    )
}

const hasCommercialLanes = (port) => {
    return port.portByPortNumber.data.commercial_vehicle_lanes.maximum_lanes != "N/A"
}


const WaitTimesGrid = ({ }) => {

    const data = useStaticQuery(graphql`
    query {
      postgres {
        allPortDatesList(filter: {portNumber: {in: ["535501","535504","535503","535502","230501","230503","230502","230902","230901","230701","231001"]}}) {
          portNumber
          portByPortNumber {
            name
            crossingName
            lastUpdatedAt
            data
          }      
          data
        }      
      }
    }
  `)

    let [port,setPort] = useState(data.postgres.allPortDatesList[0])
    let [portIndex,setPortIndex] = useState(0)

    return (
        <div className='d-flex flex-wrap'>

            {data.postgres.allPortDatesList.map((port)=>{

                return (
                    <div className='w-25'>
                        <div className='p-3 border m-3'>
                            <h3>{port.portByPortNumber.name} - {port.portByPortNumber.crossingName}</h3>
                            {hasCommercialLanes(port) &&
                                <div>
                                    Commercial Lanes
                                    <div>
                                        <Lane title="Standard" lane="commercial" laneType="standard" port={port} data={port.portByPortNumber.data.commercial_vehicle_lanes.standard_lanes} />
                                        <Lane title="Fast" lane="commercial" laneType="fast" port={port} data={port.portByPortNumber.data.commercial_vehicle_lanes.FAST_lanes} />
                                    </div>
                                </div>
                            }
                            <div>
                                Passenger Lanes
                                <div>
                                    <Lane title="Standard" lane="passenger" laneType="standard" port={port} data={port.portByPortNumber.data.passenger_vehicle_lanes.standard_lanes} />
                                    <Lane title="Nexus" lane="passenger" laneType="nexus" port={port} data={port.portByPortNumber.data.passenger_vehicle_lanes.NEXUS_SENTRI_lanes} />
                                    <Lane title="Ready" lane="passenger" laneType="ready" port={port} data={port.portByPortNumber.data.passenger_vehicle_lanes.ready_lanes} />
                                </div>

                            </div>
                    </div>
                </div>                                    
                )

            })}

        </div>
    )
}
export default WaitTimesGrid
