import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import WaitTimesGrid from '../components/WaitTimesGrid'

const Crossings = () => (
  <Layout>

    <WaitTimesGrid />

  </Layout>
)

export default Crossings
